import { defineNuxtPlugin } from "#app";
import * as echarts from "echarts";
import VueEcharts from "vue-echarts";

export default defineNuxtPlugin((nuxtApp) => {
  // Регистрируем компонент VueECharts как глобальный
  nuxtApp.vueApp.component("VChart", VueEcharts);
  // Делаем echarts доступным для использования в компонентах
  nuxtApp.provide("echarts", echarts);
});
